import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTSVG } from '../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { InspectionListFilterDatePicker } from './InspectionListFilterDatePicker';
import { format } from 'date-fns'
import { InspectionListFilterModelBrandFields } from './InspectionListFilterModelBrandFields'

import { useQueryBrandResponse } from './../../../inspection-detail/core/BrandListSelectProvider'
import { useQueryModelResponse } from './../../../inspection-detail/core/ModelListSelectProvider'
import { useModelListSelectData } from './../../../inspection-detail/core/ModelUsersListSelectProvider'

import { User } from '../../../../company-management/company-detail/users-list/core/_models'
import { useAuth } from '../../../../../../app/modules/auth'

const InspectionListFilter = () => {
	const { updateState } = useQueryRequest()
	const { isLoading } = useQueryResponse()

	const brandResponseData = useQueryBrandResponse()
	const modelResponseData = useQueryModelResponse()
	const userSelectList = useModelListSelectData()

	const [userId, setUserId] = useState<string>()
	const [status, setStatus] = useState<string | undefined>()
	const [isValid, setValid] = useState<string | undefined>()
	const [createdFrom, setCreatedFrom] = useState<Date | null>(null);
	const [createdTo, setCreatedTo] = useState<Date | null>(null);
	const [updatedFrom, setUpdatedFrom] = useState<Date | null>(null);
	const [updatedTo, setUpdatedTo] = useState<Date | null>(null);
    const { currentUser } = useAuth()

	useEffect(() => {
		MenuComponent.reinitialization()
	}, [])

	const resetData = () => {
		updateState({ filter: undefined, ...initialQueryState })
		setStatus('')
		setValid('')
		setCreatedFrom(null)
		setCreatedTo(null)
		setUpdatedFrom(null)
		setUpdatedTo(null)
		setUserId('')
		brandResponseData.setSelectedOptionId(0)
		modelResponseData.setSelectedOptionId(0)
	}

	const filterData = () => {
		updateState({
			filter: {
				brandId: brandResponseData.selectedOptionId || '',
				modelId: modelResponseData.selectedOptionId || '',
				status,
				isValid,
				userId,
				createdFrom: createdFrom ? format(createdFrom, 'yyyy-MM-dd') : '',
				createdTo: createdTo ? format(createdTo, 'yyyy-MM-dd') : '',
				updatedFrom: updatedFrom ? format(updatedFrom, 'yyyy-MM-dd') : '',
				updatedTo: updatedTo ? format(updatedTo, 'yyyy-MM-dd') : '',
			},
			...initialQueryState,
		})
	}

	const prepareOptionList = (userSelectList: Array<User>) => {
		if (!userSelectList || !Array.isArray(userSelectList)) {
			return;
		}

		return userSelectList.map((model) => {
			return <option key={model.id} value={model.id || 0}>{model.name + ' ' + model.surname}</option>;
		});
	}

	return (
		<>
			<button
				disabled={isLoading}
				type='button'
				className='btn btn-light-danger me-3'
				data-kt-menu-trigger='click'
				data-kt-menu-placement='bottom-end'
				data-kt-menu-static="true"
			>
				<KTSVG path='/media/inspection/filtry.svg' className='svg-icon-2' />Filtry</button>
			{/* end::Filter Button */}
			{/* begin::SubMenu */}
			<div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
				{/* begin::Header */}
				<div className='px-7 py-5'>
					<div className='fs-5 text-dark fw-bolder'>Filtry</div>
				</div>
				{/* end::Header */}

				{/* begin::Separator */}
				<div className='separator border-gray-200'></div>
				{/* end::Separator */}

				{/* begin::Content */}
				<div className='px-7 py-5' data-kt-inspection-table-filter='form'>
					{/* begin::Input group */}
					<div className='mb-2'>
						<label className='form-label fs-6 fw-bold'>Data utworzenia od-do:</label>
						<InspectionListFilterDatePicker startDate={createdFrom} setDateFrom={setCreatedFrom} endDate={createdTo} setDateTo={setCreatedTo} />
					</div>
					<div className='mb-2'>
						<label className='form-label fs-6 fw-bold'>Data ostatniej zmiany od-do:</label>
						<InspectionListFilterDatePicker startDate={updatedFrom} setDateFrom={setUpdatedFrom} endDate={updatedTo} setDateTo={setUpdatedTo} />
					</div>
                    {currentUser?.companyId &&
                        <div className='mb-2'>
                            <label className='form-label fs-6 fw-bold'>Inspekcje realizowane przez użytkownika</label>
                            <select
                                className='form-select form-select-solid fw-bolder'
                                data-kt-select='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                                data-kt-inspection-table-filter='status'
                                data-hide-search='true'
                                onChange={(e) => setUserId(e.target.value)}
                                value={userId}
                            >
                                <option key='0' value={''} />
                                {prepareOptionList(userSelectList)}
                            </select>
                        </div>
                      }
					
					<div className='mb-2'>
						<label className='form-label fs-6 fw-bold'>Status:</label>
						<select
							className='form-select form-select-solid fw-bolder'
							data-kt-select='true'
							data-placeholder='Select option'
							data-allow-clear='true'
							data-kt-inspection-table-filter='status'
							data-hide-search='true'
							onChange={(e) => setStatus(e.target.value)}
							value={status}
						>
							<option value=''></option>
							<option value='1'>W trakcie</option>
							<option value='2'>Zakończone</option>
							<option value='3'>Usunięty</option>
						</select>
					</div>

					<div className='mb-2'>
						<label className='form-label fs-6 fw-bold'>Walidacja:</label>
						<select
							className='form-select form-select-solid fw-bolder'
							data-kt-select='true'
							data-placeholder='Select option'
							data-allow-clear='true'
							data-kt-inspection-table-filter='valid'
							data-hide-search='false'
							onChange={(e) => setValid(e.target.value)}
							value={isValid}
						>
							<option value=''></option>
							<option value='1'>Spełniona</option>
							<option value='0'>Nie spełniona</option>
						</select>
					</div>

					<InspectionListFilterModelBrandFields />

					{/* begin::Actions */}
					<div className='d-flex justify-content-end'>
						<button
							type='button'
							disabled={isLoading}
							onClick={resetData}
							className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
							data-kt-menu-dismiss='true'
							data-kt-inspection-table-filter='reset'
						>
							Reset
           			</button>
						<button
							disabled={isLoading}
							type='button'
							onClick={filterData}
							className='btn btn-light-danger me-3 fw-bold px-6'
							data-kt-menu-dismiss='true'
							data-kt-inspection-table-filter='filter'
						>
							Wyślij
            </button>
					</div>
					{/* end::Actions */}
				</div>
				{/* end::Content */}
			</div>
			{/* end::SubMenu */}
		</>
	)
}

export { InspectionListFilter }
